
  .ant-menu-dark.ant-menu-horizontal {
    background: white;
  }
  .livenow {
    width: 30px;
    padding: 10px 40px 16px 40px;
    margin: 0 auto;
    display: inline-block;
  }
  .livenow > div {
    vertical-align: middle;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    position: absolute;
    margin: 0 auto;
    border:3px solid rgba(57,167,95,1);
    -webkit-animation: live 1.4s infinite ease-in-out;
    animation: live 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .child1 {
    background-color:rgba(57,167,95,0.3) !important;
    background-color:rgba(57,167,95,1) !important;
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s !important;
  }
  .child2 {
    -webkit-animation-delay: 0.16s;
    animation-delay: 0.16s !important;
  }
  .child3 {
    -webkit-animation-delay: 0.42s;
    animation-delay: 0.42s !important;
    border:3px solid rgba(57,167,95,0.5) !important;
  }
  .child4 {
    border:3px solid rgba(57,167,95,1) !important;
    -webkit-animation-delay: -0.42s;
    animation-delay: -0.42s !important;
  }
  
  @-webkit-keyframes live {
    0%, 80%, 100% { -webkit-transform: scale(0.6) }
    40% { -webkit-transform: scale(1.0) }
  }
  @keyframes live {
    0%, 80%, 100% { 
      transform: scale(0.6);
      -webkit-transform: scale(0.6);
    } 40% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
  