.message {
  display: flex;
  align-items: center;
  background: #ffffff;
}

.send {
  flex-direction: row-reverse;
}

.send p {
  align-self: flex-end;
}

.str-chat__main-panel {
  padding: 0px !important;
}

.str-chat-channel {
  height: 100% !important;
  width: 100%;
}

.str-chat__reverse-infinite-scroll {
  padding-top: 16px !important;
}
