.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #2ba796 !important;
}
.ant-input:hover {
  border-color: #2ba796 !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #2ba796 !important;
  box-shadow: 0 0 0 2px rgba(43, 167, 150, 0.2) !important;
}

table td,
table th {
  vertical-align: middle;
}

.container-flex__overflow {
  height: 75vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container-flex::-webkit-scrollbar {
  display: none;
}

.container-border {
  display: flex;
}

.container-border__tabs {
  border-radius: 10px;
  border-left: 1px solid #2ba796;
  border-top: 1px solid #2ba796;
  border-right: 1px solid #2ba796;
  box-sizing: border-box;
  border-radius: 8px 8px 0px 0px;
  height: 50px;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}

.container-boder__body {
  border-radius: 10px;
  border: 1px solid #2ba796;
  box-sizing: border-box;
  border-radius: 0px 8px 8px 8px;
  align-items: center;
  padding-left: 10px;
}

.container-inclusion-exclusion {
  border: 1px solid #2ba796;
  border-radius: 8px;
  align-items: center;
  padding: 10px;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6 !important;
}
