@import "https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.2/css/bulma.min.css";
@import url("https://fonts.googleapis.com/css2?family=Sarabun&display=swap");
body {
  font-family: "Sarabun", sans-serif;
}
.ant-modal-header {
  border-radius: 24px 24px 0px 0px !important;
}
.ant-modal-content {
  border-radius: 24px 24px 24px 24px !important;
}
.summary-button {
  border: none !important;
  border-radius: 24px !important;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    #cecece !important;
  color: #00bae5 !important;
}
.summary-secondary-button {
  border: none !important;
  border-radius: 24px !important;
  background: #2ba275 !important;
  color: #fff !important;
}

.ant-menu-dark.ant-menu-horizontal {
  background: white !important;
  color: black !important;
}

.ant-menu-dark {
  color: black !important;
}
.ant-menu-item {
  color: black !important;

}

.ant-layout-header {
  background: white !important;

}